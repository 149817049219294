html,
body {
  background-color: var(--primary);
  margin: 0;
  padding: 0;
  --primary: #83d1ff;

  font-family: "Roboto", sans-serif;
}

a {
  color: transparent;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("./fundo-mar.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.logo-wrapper {
  width: 200px;
  position: absolute;
  left: 5%;
  top: 5%;
}

.logo {
  width: 100%;
  height: 100%;
}

.main-image {
  margin: 10vh 0 0;
  width: 50%;
  height: 20%;
  display: flex;
  align-items: center;
}

.main-image img {
  max-width: 400px;
  width: 50%;
}

.main-image i {
  display: block;
  color: var(--primary);
  font-size: 5rem;
  width: 35%;
  display: flex;
  justify-content: flex-end;
}
.message {
  padding-top: 5vh;
  margin: 0;
  width: 100%;
}
.message p {
  color: white;
  margin: 1rem;
  padding: 0 8%;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2rem;
}

footer {
  width: 100vw;
  padding: 0 30%;
}

.icons-container {
  margin: 3rem 30% 0;

  display: flex;
  justify-content: center;
  align-self: center;
}

.icons-container a {
  margin: 0 1rem;
  border-radius: 50px;
  padding: 10px;
  transition: 0.4s;
}

.icons-container a i {
  font-size: 3rem;

  color: white;
  transition: 0.3s;
}
.icons-container a:hover {
  background-color: transparent;
}
.icons-container a:hover i {
  color: var(--primary);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.info {
  color: var(--primary);
  margin-top: 1rem;
  text-align: center;
}
.info-2 {
  color: var(--primary);
  margin-top: 0.2rem;
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .logo-wrapper {
    width: 30%;
    top: 2%;
  }

  .main-image {
    margin-top: 20%;
    width: 80%;
  }
  .main-image img {
    width: 50%;
    min-width: 200px;
  }
  .main-image i {
    font-size: 15vw !important;
  }
  .message {
    padding-top: 0;
    width: 100%;
    text-align: justify;
  }
  .message p {
    font-size: 1rem;
    line-height: 1.8rem;
    padding: 0;
  }
  footer {
    margin: 0;
  }
  .icons-container {
    margin: 0 15%;
  }
  .icons-container a {
    margin: 0 5%;
  }
  .icons-container a i {
    font-size: 2rem;
  }
  .info {
    font-size: 0.8rem;
    margin-top: 5%;
  }
}
